























































import { Component, Vue } from 'vue-property-decorator'
import * as NaireAction from '@/api/naire'
import * as UserAction from '@/api/user'
import { UserModule } from '@/store/modules/user'
import { IApiClassItem } from '@/api/types'

@Component
export default class extends Vue {
  private loading: boolean = false
  private pageParams: {
    current: number,
    page_size: number
  } = {
    current: 1,
    page_size: 10
  }
  private list: any[] = []
  private total: number = 0
  private searchParams: {
    status: number,
    u_class: string
  } = {
    status: -1,
    u_class: ''
  }
  private classList: IApiClassItem[] = []

  onChangePage (val: number) {
    this.pageParams.current = val
    this.fetchData()
  }

  handleSearch () {
    this.pageParams.current = 1
    this.fetchData()
  }

  handleExport () {
    const api = process.env.VUE_APP_BASE_API
    const nId = this.$route.params.id
    const token = UserModule.token
    window.location.href = `${api}/naire/exportStatis?token=${token}&n_id=${nId}`
  }

  // 查看回收情况 数据获取
  async fetchData () {
    this.loading = true
    const res = await NaireAction.submitStatistic({
      n_id: this.$route.params.id,
      ...this.pageParams,
      ...this.searchParams
    })
    this.loading = false
    if (res.success) {
      this.list = res.data.data
      this.total = res.data.total
    } else {
      this.$message.error('获取数据失败')
    }
  }

  async getClass () {
    this.loading = true
    const res = await UserAction.getClass()
    this.loading = false
    if (res.success) {
      this.classList = res.data || []
    } else {
      this.$message.error(res.msg)
    }
  }

  async mounted () {
    await this.getClass()
    await this.fetchData()
  }
}
